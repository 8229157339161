import axios, { AxiosError } from 'axios';
import { ApiError, AxiosCustomError } from '#lib/error/errors';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['x-mn-api-version'] = process.env.API_VERSION;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const baseURL = `${process.env.API_URL}`;
if (!baseURL) {
  throw new Error('BASE_URL IS MISSING');
}

const apiClient = axios.create({
  baseURL,
  withCredentials: true,
});

apiClient.interceptors.response.use(
  res => res,
  (err: AxiosError<AxiosCustomError>) => {
    const apiErr = new ApiError(err);
    return Promise.reject(apiErr);
  },
);

export default apiClient;
