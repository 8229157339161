export function addEmptySomething({ arr, max, value }: { arr: any; max: number; value: any }) {
  const newArr = [];
  for (let i = 0; i < max; i += 1) {
    if (!arr || !arr[i]) {
      newArr.push(value);
    } else {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}
