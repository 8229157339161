import axios from './apiClient';
import { ApplicationsStatusQueries, ApplicationJoinStatusQueries } from '#types/apiQueries';
import { makeUrlQueries } from '@miniintern/utils';
import { ApplySettleAccountPopupPropsType } from '#types/popupProps';
import {
  WorkingConditionDataType,
  NcsCategoriesType,
  ApplySettleAccountApplicationQueries,
  ApplicationType,
  Portfolio,
  MiniinternPortfolio,
  MyProjectType,
  WorkingConditionType,
} from '#types/data';
import { ResumeType } from '#types/resume';
import { Event } from '#types/event';
import {
  GetMySkillupCourseListQueryType,
  GetMySkillupPayHistoryQueryType,
  MySkillupCourse,
  MySkillupPayHistory,
} from '#types/skillup';

//* 헤드헌팅 정보 가져오기
export const getWorkingConditionAPI = () =>
  axios.get<WorkingConditionType>('/api/v2/me/desired-working-condition');

export const editWorkingCondition = (workingCondition: any) =>
  axios.patch<WorkingConditionDataType>('/api/v2/me/desired-working-condition', workingCondition);

//* 직무 검색
export const getNcsCategories = (name: string) =>
  axios.get<NcsCategoriesType[]>(`api/v2/ncs-categories?name=${name}`);

export const getApplicantResumeList = () => axios.get<ResumeType[]>('/api/v2/me/resumes');

export const setLastSelectedResumeId = (lastSelectedResumeUrlSlug: string) =>
  axios.put<number>(`/api/v2/me/resumes/last-selected/${lastSelectedResumeUrlSlug}`);

export const getApplicantPortfolioList = () => axios.get<Portfolio[]>('/api/v2/me/portfolios');

export const getApplicantMiniinternPortfolioList = () =>
  axios.get<MiniinternPortfolio[]>('/api/v2/me/proposals?step=final');

export const deleteApplicantPortfolio = (portfolioId: number) =>
  axios.delete(`/api/v2/me/portfolios/${portfolioId}`);

export const getApplicationsStatus = (applicationsStatusQuery: ApplicationsStatusQueries) =>
  axios.get(`/api/v2/me/recruitments/${makeUrlQueries('applications', applicationsStatusQuery)}`);

export const getApplication = (queries: ApplySettleAccountApplicationQueries) =>
  axios.get<ApplicationType>(`/api/v2/me/recruitments/applications/${queries.noticeUrlSlug}`);

export const patchApplicationJoinStatus = ({
  applicationId,
  status,
}: ApplicationJoinStatusQueries) =>
  axios.patch(`/api/v3/recruitment-notices/applications/${applicationId}/join-status`, { status });

export const postApplySettleAccount = (body: ApplySettleAccountPopupPropsType) =>
  axios.post('/api/v3/recruitment-notices/settle-accounts', body);

export type secedersParamsType = {
  answers: { answer: string; isEtc: boolean }[];
};

export const secedersAccountAPI = (params: secedersParamsType) =>
  axios.delete('/api/v2/me/seceders', { data: { ...params } });

export const secedersSubSocialAccountAPI = (params: { socialName: 'kakao' | 'naver' }) =>
  axios.patch(`/api/v2/me/seceders/${params.socialName}/unlink`);

//* 신청한 미니인턴 리스트 불러오기
export const getMyProjects = () => axios.get<MyProjectType[]>('api/v2/me/projects?status=all');

//* 신청한 이벤트 리스트 받아오기
export const getAppliedEventsAPI = () => axios.get<Event[]>('api/v2/me/events?status=all');

//* 휴면 유저 상태 해제
export const changeDormancyStatus = () => axios.patch('api/v2/me/dormancy-status');

//* 개인정보 보관기간 변경
export const patchValidPrivacyInfo = (privacyInfoValidPeriod: number) =>
  axios.patch('/api/v2/me/profiles/valid/privacy-info', { privacyInfoValidPeriod });

//* 마이커리어 > 스킬업 강의 리스트 불러오기
export const getMySkillupCoursesAPI = (mySkillupCourseQuery: GetMySkillupCourseListQueryType) =>
  axios.get<MySkillupCourse>(makeUrlQueries('api/v2/me/skillup/courses', mySkillupCourseQuery));

//* 마이커리어 > 스킬업 결제내역 리스트 불러오기
export const getMySkillupPayHistoryAPI = (
  mySkillupPayHistoryQuery: GetMySkillupPayHistoryQueryType,
) =>
  axios.get<MySkillupPayHistory>(
    makeUrlQueries('api/v2/me/skillup/pay-history', mySkillupPayHistoryQuery),
  );

//* 마이커리어 > 스킬업 환불신청
export const postSkillupRefundAPI = (id: number, refundQuestion: string) =>
  axios.post<MySkillupPayHistory>(`api/v2/me/skillup/pay-history/${id}/refund`, { refundQuestion });
