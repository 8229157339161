import { WorkingConditionSubType } from '#types/data';

interface ProjectListType {
  name: string;
  value: string;
}
export type ProjectFieldType = {
  checked: boolean;
} & ProjectListType;

interface WorkingConditionListType {
  value:
    | 'wishfulJob'
    | 'annualIncome'
    | 'wishfulArea'
    | 'businessScale'
    | 'employmentType'
    | 'jobValueView';
  text: string;
  isEdit: boolean;
}

export interface WorkingConditionChekedType extends WorkingConditionSubType {
  checked: boolean;
}

export const projectStatusList: ProjectListType[] = [
  {
    name: '전체',
    value: 'all',
  },
  {
    name: '모집 중',
    value: 'recruiting',
  },
  {
    name: '모집 종료',
    value: 'recruited',
  },
];

export const proejectFieldList: ProjectFieldType[] = [
  {
    name: '기획',
    value: '1',
    checked: false,
  },
  {
    name: '아이디어제안',
    value: '2',
    checked: false,
  },
  {
    name: '디자인',
    value: '3',
    checked: false,
  },
  {
    name: '마케팅',
    value: '4',
    checked: false,
  },
  {
    name: '슬로건/네이밍',
    value: '5',
    checked: false,
  },
  {
    name: '건축/인테리어',
    value: '6',
    checked: false,
  },
  {
    name: '전시/페스티발',
    value: '7',
    checked: false,
  },
  {
    name: '봉사활동',
    value: '8',
    checked: false,
  },
  {
    name: '프로그램개발',
    value: '9',
    checked: false,
  },
  {
    name: '행사',
    value: '10',
    checked: false,
  },
  {
    name: '영상/UCC/사진',
    value: '12',
    checked: false,
  },
  {
    name: '문학/시나리오',
    value: '13',
    checked: false,
  },
  {
    name: '과학/공학',
    value: '14',
    checked: false,
  },
  {
    name: '창의대회',
    value: '15',
    checked: false,
  },
];

export const eventRecruitingOptions = [
  { value: '', label: '전체' },
  { value: 'recruiting', label: '모집 중' },
  { value: 'finished', label: '모집 종료' },
];

export const eventCategoryOptions = [
  { value: '', label: '전체' },
  { value: '취업', label: '취업' },
  { value: '창업', label: '창업' },
  { value: '진로', label: '진로' },
  { value: '진학', label: '진학' },
  { value: '기타', label: '기타' },
];

export const workingConditionList: WorkingConditionListType[] = [
  {
    value: 'wishfulJob',
    text: '희망 직무',
    isEdit: false,
  },
  {
    value: 'annualIncome',
    text: '희망 연봉',
    isEdit: false,
  },
  {
    value: 'wishfulArea',
    text: '희망 근무지역',
    isEdit: false,
  },
  {
    value: 'businessScale',
    text: '희망 기업규모',
    isEdit: false,
  },
  {
    value: 'employmentType',
    text: '희망 고용형태',
    isEdit: false,
  },
  {
    value: 'jobValueView',
    text: '직업 가치관',
    isEdit: false,
  },
];

export const businessScaleList: WorkingConditionChekedType[] = [
  { id: 256, value: '스타트업', category: 'businessScale', checked: false },
  { id: 257, value: '중소기업', category: 'businessScale', checked: false },
  { id: 258, value: '중견기업', category: 'businessScale', checked: false },
  { id: 259, value: '대기업', category: 'businessScale', checked: false },
];

export const employmentList: WorkingConditionChekedType[] = [
  { id: 260, value: '정규직', category: 'employmentType', checked: false },
  { id: 261, value: '계약직', category: 'employmentType', checked: false },
  { id: 262, value: '인턴', category: 'employmentType', checked: false },
];

export const internPeriodList: WorkingConditionChekedType[] = [
  { id: 263, value: '봄 시즌 (3~5월)', category: 'internPeriod', checked: false },
  { id: 264, value: '여름 시즌 (6~8월)', category: 'internPeriod', checked: false },
  { id: 265, value: '가을 시즌 (9~11월)', category: 'internPeriod', checked: false },
  { id: 266, value: '겨울 시즌 (12~2월)', category: 'internPeriod', checked: false },
];

export const annualSalaryList: WorkingConditionSubType[] = [
  { id: 245, value: '회사 내규에 따름', category: 'annualSalary' },
  { id: 246, value: '2,500~3,000만원', category: 'annualSalary' },
  { id: 247, value: '3,000~3,500만원', category: 'annualSalary' },
  { id: 248, value: '3,500~4,000만원', category: 'annualSalary' },
  { id: 249, value: '4,000~4,500만원', category: 'annualSalary' },
  { id: 250, value: '4,500~5,000만원', category: 'annualSalary' },
  { id: 251, value: '5,000~6,000만원', category: 'annualSalary' },
  { id: 252, value: '6,000~7,000만원', category: 'annualSalary' },
  { id: 253, value: '7,000~8,000만원', category: 'annualSalary' },
  { id: 254, value: '8,000만원 이상', category: 'annualSalary' },
];

export const jobValueViewList: WorkingConditionSubType[] = [
  { id: 270, value: '연봉', category: 'jobValueView' },
  { id: 271, value: '복지', category: 'jobValueView' },
  { id: 272, value: '비전', category: 'jobValueView' },
  { id: 273, value: '문화', category: 'jobValueView' },
];
