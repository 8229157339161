import { useDispatch } from 'react-redux';
import { coreActions } from '#modules/core';
import { type ToasterType } from '@miniintern/ui';

interface toastParameter {
  message: string;
  type: ToasterType;
  callback?: () => void;
}
const useToaster = () => {
  const dispatch = useDispatch();

  const openToast = ({ message, type, callback }: toastParameter) => {
    dispatch(
      coreActions.setToaster({
        message,
        type,
        visible: true,
        fn: callback || null,
      }),
    );
  };

  const closeToast = () =>
    dispatch(
      coreActions.setToaster({
        message: '',
        type: null,
        visible: false,
        fn: null,
      }),
    );
  return { openToast, closeToast };
};
export default useToaster;
