export type locationType = {
  id: number;
  value: string;
  label: string;
};

export const metroLocationOptions: locationType[] = [
  { id: 2, value: '서울', label: '서울' },
  { id: 3, value: '부산', label: '부산' },
  { id: 4, value: '대구', label: '대구' },
  { id: 5, value: '인천', label: '인천' },
  { id: 6, value: '광주', label: '광주' },
  { id: 7, value: '대전', label: '대전' },
  { id: 8, value: '울산', label: '울산' },
  { id: 9, value: '경기', label: '경기' },
  { id: 10, value: '강원', label: '강원' },
  { id: 11, value: '충북', label: '충북' },
  { id: 12, value: '충남', label: '충남' },
  { id: 13, value: '세종', label: '세종' },
  { id: 14, value: '전북', label: '전북' },
  { id: 15, value: '전남', label: '전남' },
  { id: 16, value: '경북', label: '경북' },
  { id: 17, value: '경남', label: '경남' },
  { id: 18, value: '제주', label: '제주' },
];

export const metroLocationOptionsTwo: locationType[] = [
  { id: 2, value: '서울', label: '서울' },
  { id: 274, value: '경기북부', label: '경기북부' },
  { id: 275, value: '경기남부', label: '경기남부' },
  { id: 3, value: '부산', label: '부산' },
  { id: 4, value: '대구', label: '대구' },
  { id: 5, value: '인천', label: '인천' },
  { id: 6, value: '광주', label: '광주' },
  { id: 7, value: '대전', label: '대전' },
  { id: 8, value: '울산', label: '울산' },
  { id: 10, value: '강원', label: '강원' },
  { id: 11, value: '충북', label: '충북' },
  { id: 12, value: '충남', label: '충남' },
  { id: 13, value: '세종', label: '세종' },
  { id: 14, value: '전북', label: '전북' },
  { id: 15, value: '전남', label: '전남' },
  { id: 16, value: '경북', label: '경북' },
  { id: 17, value: '경남', label: '경남' },
  { id: 18, value: '제주', label: '제주' },
];

const getBasicLocationOptions = (metroLocation: string): locationType[] => {
  switch (metroLocation) {
    case '서울':
      return [
        { id: 19, value: '강남구', label: '강남구' },
        { id: 20, value: '강동구', label: '강동구' },
        { id: 21, value: '강북구', label: '강북구' },
        { id: 22, value: '강서구', label: '강서구' },
        { id: 23, value: '관악구', label: '관악구' },
        { id: 24, value: '광진구', label: '광진구' },
        { id: 25, value: '구로구', label: '구로구' },
        { id: 26, value: '금천구', label: '금천구' },
        { id: 27, value: '노원구', label: '노원구' },
        { id: 28, value: '도봉구', label: '도봉구' },
        { id: 29, value: '동대문구', label: '동대문구' },
        { id: 30, value: '동작구', label: '동작구' },
        { id: 31, value: '마포구', label: '마포구' },
        { id: 32, value: '서대문구', label: '서대문구' },
        { id: 33, value: '서초구', label: '서초구' },
        { id: 34, value: '성동구', label: '성동구' },
        { id: 35, value: '성북구', label: '성북구' },
        { id: 36, value: '송파구', label: '송파구' },
        { id: 37, value: '양천구', label: '양천구' },
        { id: 38, value: '영등포구', label: '영등포구' },
        { id: 39, value: '용산구', label: '용산구' },
        { id: 40, value: '은평구', label: '은평구' },
        { id: 41, value: '종로구', label: '종로구' },
        { id: 42, value: '중구', label: '중구' },
        { id: 43, value: '중랑구', label: '중랑구' },
      ];
    case '부산':
      return [
        { id: 44, value: '강서구', label: '강서구' },
        { id: 45, value: '금정구', label: '금정구' },
        { id: 46, value: '남구', label: '남구' },
        { id: 47, value: '동구', label: '동구' },
        { id: 48, value: '동래구', label: '동래구' },
        { id: 49, value: '부산진구', label: '부산진구' },
        { id: 50, value: '북구', label: '북구' },
        { id: 51, value: '사상구', label: '사상구' },
        { id: 52, value: '사하구', label: '사하구' },
        { id: 53, value: '서구', label: '서구' },
        { id: 54, value: '수영구', label: '수영구' },
        { id: 55, value: '연제구', label: '연제구' },
        { id: 56, value: '영도구', label: '영도구' },
        { id: 57, value: '중구', label: '중구' },
        { id: 58, value: '해운대구', label: '해운대구' },
        { id: 59, value: '기장군', label: '기장군' },
      ];
    case '대구':
      return [
        { id: 60, value: '남구', label: '남구' },
        { id: 61, value: '달서구', label: '달서구' },
        { id: 62, value: '동구', label: '동구' },
        { id: 63, value: '북구', label: '북구' },
        { id: 64, value: '서구', label: '서구' },
        { id: 65, value: '수성구', label: '수성구' },
        { id: 66, value: '중구', label: '중구' },
        { id: 67, value: '달성군', label: '달성군' },
      ];
    case '인천':
      return [
        { id: 68, value: '계양구', label: '계양구' },
        { id: 69, value: '미추홀구', label: '미추홀구' },
        { id: 70, value: '남동구', label: '남동구' },
        { id: 71, value: '동구', label: '동구' },
        { id: 72, value: '부평구', label: '부평구' },
        { id: 73, value: '서구', label: '서구' },
        { id: 74, value: '연수구', label: '연수구' },
        { id: 75, value: '중구', label: '중구' },
        { id: 76, value: '강화군', label: '강화군' },
        { id: 77, value: '옹진군', label: '옹진군' },
      ];
    case '광주':
      return [
        { id: 78, value: '남구', label: '남구' },
        { id: 79, value: '동구', label: '동구' },
        { id: 80, value: '북구', label: '북구' },
        { id: 81, value: '서구', label: '서구' },
        { id: 82, value: '광산구', label: '광산구' },
      ];
    case '대전':
      return [
        { id: 83, value: '대덕구', label: '대덕구' },
        { id: 84, value: '동구', label: '동구' },
        { id: 85, value: '서구', label: '서구' },
        { id: 86, value: '유성구', label: '유성구' },
        { id: 87, value: '중구', label: '중구' },
      ];
    case '울산':
      return [
        { id: 88, value: '남구', label: '남구' },
        { id: 89, value: '동구', label: '동구' },
        { id: 90, value: '북구', label: '북구' },
        { id: 91, value: '중구', label: '중구' },
        { id: 92, value: '울주군', label: '울주군' },
      ];
    case '경기':
      return [
        { id: 93, value: '고양시', label: '고양시' },
        { id: 94, value: '과천시', label: '과천시' },
        { id: 95, value: '광명시', label: '광명시' },
        { id: 96, value: '광주시', label: '광주시' },
        { id: 97, value: '구리시', label: '구리시' },
        { id: 98, value: '군포시', label: '군포시' },
        { id: 99, value: '김포시', label: '김포시' },
        { id: 100, value: '남양주시', label: '남양주시' },
        { id: 101, value: '동두천시', label: '동두천시' },
        { id: 102, value: '부천시', label: '부천시' },
        { id: 103, value: '성남시', label: '성남시' },
        { id: 104, value: '수원시', label: '수원시' },
        { id: 105, value: '시흥시', label: '시흥시' },
        { id: 106, value: '안산시', label: '안산시' },
        { id: 107, value: '안성시', label: '안성시' },
        { id: 108, value: '안양시', label: '안양시' },
        { id: 109, value: '양주시', label: '양주시' },
        { id: 110, value: '여주시', label: '여주시' },
        { id: 111, value: '오산시', label: '오산시' },
        { id: 112, value: '용인시', label: '용인시' },
        { id: 113, value: '의왕시', label: '의왕시' },
        { id: 114, value: '의정부시', label: '의정부시' },
        { id: 115, value: '이천시', label: '이천시' },
        { id: 116, value: '파주시', label: '파주시' },
        { id: 117, value: '평택시', label: '평택시' },
        { id: 118, value: '포천시', label: '포천시' },
        { id: 119, value: '하남시', label: '하남시' },
        { id: 120, value: '화성시', label: '화성시' },
        { id: 121, value: '가평군', label: '가평군' },
        { id: 122, value: '양평군', label: '양평군' },
        { id: 123, value: '연천군', label: '연천군' },
      ];
    case '강원':
      return [
        { id: 124, value: '강릉시', label: '강릉시' },
        { id: 125, value: '동해시', label: '동해시' },
        { id: 126, value: '삼척시', label: '삼척시' },
        { id: 127, value: '속초시', label: '속초시' },
        { id: 128, value: '원주시', label: '원주시' },
        { id: 129, value: '춘천시', label: '춘천시' },
        { id: 130, value: '태백시', label: '태백시' },
        { id: 131, value: '고성군', label: '고성군' },
        { id: 132, value: '양구군', label: '양구군' },
        { id: 133, value: '양양군', label: '양양군' },
        { id: 134, value: '영월군', label: '영월군' },
        { id: 135, value: '인제군', label: '인제군' },
        { id: 136, value: '정선군', label: '정선군' },
        { id: 137, value: '철원군', label: '철원군' },
        { id: 138, value: '평창군', label: '평창군' },
        { id: 139, value: '홍천군', label: '홍천군' },
        { id: 140, value: '화천군', label: '화천군' },
        { id: 141, value: '횡성군', label: '횡성군' },
      ];
    case '충북':
      return [
        { id: 142, value: '제천시', label: '제천시' },
        { id: 143, value: '청주시', label: '청주시' },
        { id: 144, value: '충주시', label: '충주시' },
        { id: 145, value: '괴산군', label: '괴산군' },
        { id: 146, value: '단양군', label: '단양군' },
        { id: 147, value: '보은군', label: '보은군' },
        { id: 148, value: '영동군', label: '영동군' },
        { id: 149, value: '옥천군', label: '옥천군' },
        { id: 150, value: '음성군', label: '음성군' },
        { id: 151, value: '증평군', label: '증평군' },
        { id: 152, value: '진천군', label: '진천군' },
      ];
    case '충남':
      return [
        { id: 153, value: '공주시', label: '공주시' },
        { id: 154, value: '계룡시', label: '계룡시' },
        { id: 155, value: '논산시', label: '논산시' },
        { id: 156, value: '보령시', label: '보령시' },
        { id: 157, value: '서산시', label: '서산시' },
        { id: 158, value: '아산시', label: '아산시' },
        { id: 159, value: '천안시', label: '천안시' },
        { id: 160, value: '금산군', label: '금산군' },
        { id: 161, value: '당진시', label: '당진시' },
        { id: 162, value: '부여군', label: '부여군' },
        { id: 163, value: '서천군', label: '서천군' },
        { id: 164, value: '예산군', label: '예산군' },
        { id: 165, value: '청양군', label: '청양군' },
        { id: 166, value: '태안군', label: '태안군' },
        { id: 167, value: '홍성군', label: '홍성군' },
      ];
    case '전북':
      return [
        { id: 168, value: '군산시', label: '군산시' },
        { id: 169, value: '김제시', label: '김제시' },
        { id: 170, value: '남원시', label: '남원시' },
        { id: 171, value: '익산시', label: '익산시' },
        { id: 172, value: '전주시', label: '전주시' },
        { id: 173, value: '정읍시', label: '정읍시' },
        { id: 174, value: '고창군', label: '고창군' },
        { id: 175, value: '무주군', label: '무주군' },
        { id: 176, value: '부안군', label: '부안군' },
        { id: 177, value: '순창군', label: '순창군' },
        { id: 178, value: '완주군', label: '완주군' },
        { id: 179, value: '임실군', label: '임실군' },
        { id: 180, value: '장수군', label: '장수군' },
        { id: 181, value: '진안군', label: '진안군' },
      ];
    case '전남':
      return [
        { id: 182, value: '광양시', label: '광양시' },
        { id: 183, value: '나주시', label: '나주시' },
        { id: 184, value: '목포시', label: '목포시' },
        { id: 185, value: '순천시', label: '순천시' },
        { id: 186, value: '여수시', label: '여수시' },
        { id: 187, value: '강진군', label: '강진군' },
        { id: 188, value: '고흥군', label: '고흥군' },
        { id: 189, value: '곡성군', label: '곡성군' },
        { id: 190, value: '구례군', label: '구례군' },
        { id: 191, value: '담양군', label: '담양군' },
        { id: 192, value: '무안군', label: '무안군' },
        { id: 193, value: '보성군', label: '보성군' },
        { id: 194, value: '신안군', label: '신안군' },
        { id: 195, value: '영광군', label: '영광군' },
        { id: 196, value: '영암군', label: '영암군' },
        { id: 197, value: '완도군', label: '완도군' },
        { id: 198, value: '장성군', label: '장성군' },
        { id: 199, value: '장흥군', label: '장흥군' },
        { id: 200, value: '진도군', label: '진도군' },
        { id: 201, value: '함평군', label: '함평군' },
        { id: 202, value: '해남군', label: '해남군' },
        { id: 203, value: '화순군', label: '화순군' },
      ];

    case '경북':
      return [
        { id: 204, value: '경산시', label: '경산시' },
        { id: 205, value: '경주시', label: '경주시' },
        { id: 206, value: '구미시', label: '구미시' },
        { id: 207, value: '김천시', label: '김천시' },
        { id: 208, value: '문경시', label: '문경시' },
        { id: 209, value: '상주시', label: '상주시' },
        { id: 210, value: '안동시', label: '안동시' },
        { id: 211, value: '영주시', label: '영주시' },
        { id: 212, value: '영천시', label: '영천시' },
        { id: 213, value: '포항시', label: '포항시' },
        { id: 214, value: '고령군', label: '고령군' },
        { id: 215, value: '군위군', label: '군위군' },
        { id: 216, value: '봉화군', label: '봉화군' },
        { id: 217, value: '성주군', label: '성주군' },
        { id: 218, value: '영덕군', label: '영덕군' },
        { id: 219, value: '영양군', label: '영양군' },
        { id: 220, value: '예천군', label: '예천군' },
        { id: 221, value: '울릉군', label: '울릉군' },
        { id: 222, value: '울진군', label: '울진군' },
        { id: 223, value: '의성군', label: '의성군' },
        { id: 224, value: '청도군', label: '청도군' },
        { id: 225, value: '청송군', label: '청송군' },
        { id: 226, value: '칠곡군', label: '칠곡군' },
      ];
    case '경남':
      return [
        { id: 227, value: '거제시', label: '거제시' },
        { id: 228, value: '김해시', label: '김해시' },
        { id: 229, value: '밀양시', label: '밀양시' },
        { id: 230, value: '사천시', label: '사천시' },
        { id: 231, value: '양산시', label: '양산시' },
        { id: 232, value: '진주시', label: '진주시' },
        { id: 233, value: '창원시', label: '창원시' },
        { id: 234, value: '통영시', label: '통영시' },
        { id: 235, value: '거창군', label: '거창군' },
        { id: 236, value: '고성군', label: '고성군' },
        { id: 237, value: '남해군', label: '남해군' },
        { id: 238, value: '산청군', label: '산청군' },
        { id: 239, value: '의령군', label: '의령군' },
        { id: 240, value: '창녕군', label: '창녕군' },
        { id: 241, value: '하동군', label: '하동군' },
        { id: 242, value: '함안군', label: '함안군' },
        { id: 243, value: '함양군', label: '함양군' },
        { id: 244, value: '합천군', label: '합천군' },
      ];
    case '세종':
      return [{ id: 267, value: '세종', label: '세종' }];
    case '제주':
      return [
        { id: 268, value: '제주시', label: '제주시' },
        { id: 269, value: '서귀포시', label: '서귀포시' },
      ];
    default:
      return [{ id: 0, value: '', label: '' }];
  }
};
export default getBasicLocationOptions;
