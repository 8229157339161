import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as meAPI from '#lib/api/me';
import isNull from 'lodash/isNull';
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import { ApplicationJoinStatusQueries, ApplicationsStatusQueries } from '#types/apiQueries';
import {
  ApplicationsStatusType,
  ApplicationsTabStatusType,
  ApplicationType,
  ApplySettleAccountApplicationQueries,
  MyProjectType,
  Portfolio,
  WorkingConditionType,
} from '#types/data';
import { ParticipatedEvent } from '#types/event';
import {
  ApplicantFilesType,
  MeState,
  setErrorPayload,
  setWorkingConditionArrPayload,
  setWorkingConditioPayload,
} from '#types/redux/meModule';
import { ResumeType } from '#types/resume';
import { annualSalaryList } from '#lib/selectListMap';
import { addEmptySomething } from '@miniintern/utils';
import { coreActions } from './core';
import { sendErrorToSentry } from '#lib/error';
import {
  GetMySkillupCourseListQueryType,
  GetMySkillupPayHistoryQueryType,
  MySkillupCourseList,
  MySkillupPayHistory,
} from '#types/skillup';
import { metroLocationOptionsTwo } from '#lib/locationOptions';

export const meState: MeState = {
  myProjects: {
    projects: [],
    isLoading: true,
  },
  activedProject: -1,
  myEvents: {
    events: [],
    isLoading: true,
  },
  mySkillupCourses: {
    courseList: [],
    count: 0,
    isLoading: true,
  },
  mySkillupPayHistory: {
    payHistoryList: [],
    count: 0,
    isLoading: true,
  },
  activedEvent: -1,
  isShownWorkingConditionSetting: false,
  workingCondition: {
    id: 0,
    resume: null,
    proposal: null,
    portfolio: null,
    subscribeMatchingJob: false,
    annualSalary: {
      id: 0,
      value: '',
      category: '',
    },
    businessScales: [],
    employmentTypes: [],
    internPeriods: [],
    locations: [],
    ncsCategories: [],
    jobValueViews: [],
    createdAt: null,
    updatedAt: null,
    isError: {
      jobValueViews: '',
      businessScales: '',
    },
  },
  modal: {
    index: null,
  },
  applicantFiles: {
    resumeList: [],
    portfolioList: [],
    miniinternPortfolioList: [],
    isLoading: true,
  },
  applicationsStatus: {
    tabStatus: 'all',
    applications: [],
    count: 0,
    targetApplicationId: null,
    applySettleAccountApplication: null,
    isLoading: true,
  },
};
const me = createSlice({
  name: 'me',
  initialState: meState,
  reducers: {
    setFoucusEvent: (state, action: PayloadAction<number>) => {
      state.activedEvent = action.payload;
    },
    getMyEvents: state => {
      state.myEvents.isLoading = true;
    },
    getMyEventsSuccess: (state, action: PayloadAction<ParticipatedEvent[]>) => {
      state.myEvents.isLoading = false;
      state.myEvents.events = action.payload;
    },
    getMyEventsFailure: state => {
      state.myEvents.isLoading = false;
    },
    changeMyEventCanceledStatus: (state, action: PayloadAction<{ index: number }>) => {
      if (state.myEvents.events[action.payload.index].price > 0) {
        state.myEvents.events[action.payload.index].participation!.cancelStatus = 'applied';
      } else {
        state.myEvents.events[action.payload.index].participation!.cancelStatus = 'done';
      }
    },
    setFoucesProject: (state, action: PayloadAction<number>) => {
      state.activedProject = action.payload;
    },
    getMySkillupCourses: (state, _action: PayloadAction<GetMySkillupCourseListQueryType>) => {
      state.mySkillupCourses.isLoading = true;
    },
    getMySkillupCoursesSuccess: (state, action: PayloadAction<MySkillupCourseList>) => {
      state.mySkillupCourses.isLoading = false;
      state.mySkillupCourses.courseList = action.payload.enrolledUserCourses;
      state.mySkillupCourses.count = action.payload.count;
    },
    getMySkillupCoursesFailure: state => {
      state.mySkillupCourses.isLoading = false;
    },
    getMySkillupPayHistory: (state, _action: PayloadAction<GetMySkillupPayHistoryQueryType>) => {
      state.mySkillupPayHistory.isLoading = true;
    },
    getMySkillupPayHistorySuccess: (state, action: PayloadAction<MySkillupPayHistory>) => {
      state.mySkillupPayHistory.isLoading = false;
      state.mySkillupPayHistory.payHistoryList = action.payload.payHistoryList;
      state.mySkillupPayHistory.count = action.payload.count;
    },
    getMySkillupPayHistoryFailure: state => {
      state.mySkillupPayHistory.isLoading = false;
    },
    setMySkillupPayHistoryStatus: (state, action: PayloadAction<{ id: number }>) => {
      const targetPayHistoryIndex = state.mySkillupPayHistory.payHistoryList.findIndex(obj =>
        obj.payMonthList.some(item => item.id === action.payload.id),
      );
      const targetPayMonthIndex = state.mySkillupPayHistory.payHistoryList[
        targetPayHistoryIndex
      ].payMonthList.findIndex(obj => obj.id === action.payload.id);

      const targetPayHistoryItem =
        state.mySkillupPayHistory.payHistoryList[targetPayHistoryIndex].payMonthList[
          targetPayMonthIndex
        ];
      targetPayHistoryItem.isRefundable = false;
      targetPayHistoryItem.payStatus = 'refundPending';
    },
    getMyProjects: state => {
      state.myProjects.isLoading = true;
    },
    getMyProjectsSuccess: (state, action: PayloadAction<MyProjectType[]>) => {
      state.myProjects.projects = action.payload;
      state.myProjects.isLoading = false;
    },
    getMyProjectsFailure: state => {
      state.myProjects.isLoading = false;
    },
    setWorkingCondition: (_state, _action: PayloadAction<setWorkingConditioPayload>) => {},
    setWorkingConditionSuccess: (state, action: PayloadAction<WorkingConditionType>) => {
      const data = action.payload;
      const { ncsCategories, locations, jobValueViews } = data;
      state.workingCondition = { ...state.workingCondition, ...data };
      if (!ncsCategories || ncsCategories.length < 3) {
        state.workingCondition.ncsCategories = addEmptySomething({
          arr: ncsCategories,
          max: 3,
          value: '',
        });
      }
      if (!locations || locations.length < 2) {
        state.workingCondition.locations = addEmptySomething({
          arr: locations,
          max: 2,
          value: { id: 0, value: '', parent: { id: 0, value: '' } },
        });
      }
      if (!jobValueViews) {
        state.workingCondition.jobValueViews = addEmptySomething({
          arr: jobValueViews,
          max: 4,
          value: { id: 0, value: '', category: 'jobValueView' },
        });
      } else if (jobValueViews.filter(v => !v).length > 0) {
        const defaultObj = { id: 0, value: '', category: 'jobValueView' };
        const result = jobValueViews.map(view => (!view ? defaultObj : view));
        state.workingCondition.jobValueViews = result;
      }
    },
    setIsShownWorkingConditionSetting: (state, action: PayloadAction<boolean>) => {
      state.isShownWorkingConditionSetting = action.payload;
    },
    setHeadHuntingModalIndex: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.modal.index = index;
    },
    setNcsCategory: (state, action: PayloadAction<{ jobname: string }>) => {
      const { index } = state.modal;
      if (isNull(index)) return;
      const { jobname } = action.payload;
      const { ncsCategories } = state.workingCondition;
      const newArr = ncsCategories.map((name, i) => {
        if (i !== index) return name;
        return jobname;
      });
      state.workingCondition.ncsCategories = newArr;
      state.modal.index = null;
    },
    setAnnualSalary: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const filtered = annualSalaryList.filter(annual => annual.id === id)[0];
      state.workingCondition.annualSalary = filtered;
    },
    setLocation: (
      state,
      action: PayloadAction<{
        index: number;
        value: string;
      }>,
    ) => {
      const { index, value } = action.payload;
      if (state.workingCondition.locations.length >= 1) {
        const found = metroLocationOptionsTwo.find(item => item.value === value);
        if (found) {
          state.workingCondition.locations[index].id = found.id;
          state.workingCondition.locations[index].value = found.value;
        }
      }
    },
    setWorkingConditionArr: (state, action: PayloadAction<setWorkingConditionArrPayload>) => {
      const { arr, type } = action.payload;
      state.workingCondition[type] = arr;
    },
    setError: (state, action: PayloadAction<setErrorPayload>) => {
      const { type, message } = action.payload;
      if (type === 'jobValueViews') {
        state.workingCondition.isError.jobValueViews = message;
      }
      if (type === 'businessScales') {
        state.workingCondition.isError.businessScales = message;
      }
    },
    setApplicationsTabStatus: (state, action: PayloadAction<ApplicationsTabStatusType>) => {
      state.applicationsStatus.tabStatus = action.payload;
    },
    setTargetApplicationId: (state, action: PayloadAction<number>) => {
      state.applicationsStatus.targetApplicationId = action.payload;
    },
    setApplySettleAccountApplication: (
      _state,
      _action: PayloadAction<ApplySettleAccountApplicationQueries>,
    ) => {},
    setApplySettleAccountApplicationSuccess: (state, action: PayloadAction<ApplicationType>) => {
      state.applicationsStatus.applySettleAccountApplication = action.payload;
    },
    setMatchingJob: (_state, _action: PayloadAction<boolean>) => {},
    setMatchingJobSuccess: (state, action: PayloadAction<boolean>) => {
      state.workingCondition.subscribeMatchingJob = action.payload;
    },
    setApplicantFiles: state => {
      state.applicantFiles.isLoading = true;
    },
    setApplicantFilesSuccess: (state, action: PayloadAction<ApplicantFilesType>) => {
      state.applicantFiles = action.payload;
      state.applicantFiles.isLoading = false;
    },
    setApplicantFilesFailure: state => {
      state.applicantFiles.isLoading = false;
    },
    getResumeList: state => {
      state.applicantFiles.isLoading = true;
    },
    getResumeListSuccess: (state, action: PayloadAction<ResumeType[]>) => {
      state.applicantFiles.resumeList = action.payload;
      state.applicantFiles.isLoading = false;
    },
    getResumeListFailure: state => {
      state.applicantFiles.isLoading = false;
    },
    addResumeList: (state, action: PayloadAction<ResumeType>) => {
      state.applicantFiles.resumeList.unshift(action.payload);
    },
    uploadApplicantPortfolio: (state, action: PayloadAction<Portfolio[]>) => {
      state.applicantFiles.portfolioList = action.payload;
    },
    deleteApplicantPortfolio: (_state, _action: PayloadAction<number>) => {},
    deleteApplicantPortfolioSuccess: (state, action: PayloadAction<number>) => {
      // 해당 id값을 가지는 포트폴리오 삭제
      state.applicantFiles.portfolioList = state.applicantFiles.portfolioList.filter(
        portfolio => portfolio.id !== action.payload,
      );
    },

    setApplicationsStatus: (state, _action: PayloadAction<ApplicationsStatusQueries>) => {
      state.applicationsStatus.isLoading = true;
    },
    setApplicationsStatusSuccess: (state, action: PayloadAction<ApplicationsStatusType>) => {
      state.applicationsStatus.isLoading = false;
      state.applicationsStatus.applications = action.payload.applications;
      state.applicationsStatus.count = action.payload.count;
    },
    setApplicationJoinedStatus: (
      _state,
      _action: PayloadAction<ApplicationJoinStatusQueries>,
    ) => {},
    setApplicationJoinedStatusSuccess: (
      state,
      action: PayloadAction<ApplicationJoinStatusQueries>,
    ) => {
      const targetApplicationIndex = state.applicationsStatus.applications.findIndex(
        application => application.id === action.payload.applicationId,
      );
      state.applicationsStatus.applications[targetApplicationIndex].joinedStatus =
        action.payload.status;
    },
  },
});

export const meActions = me.actions;

function* watchGetMyProjects() {
  yield takeLatest(meActions.getMyProjects.type, function* () {
    try {
      const { data } = yield call(meAPI.getMyProjects);
      yield put({
        type: meActions.getMyProjectsSuccess.type,
        payload: data,
      });
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: meActions.getMyProjectsFailure.type,
      });
    }
  });
}

function* watchGetMySkillupCourses() {
  yield takeLatest(meActions.getMySkillupCourses, function* (action) {
    try {
      const { data } = yield call(meAPI.getMySkillupCoursesAPI, {
        page: action.payload.page,
        order: action.payload.order,
        limit: '20',
      });
      yield put({
        type: meActions.getMySkillupCoursesSuccess.type,
        payload: data,
      });
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: meActions.getMySkillupCoursesFailure.type,
      });
    }
  });
}

function* watchGetMySkillupPayHistory() {
  yield takeLatest(meActions.getMySkillupPayHistory, function* (action) {
    try {
      const { data } = yield call(meAPI.getMySkillupPayHistoryAPI, {
        page: action.payload.page,
        limit: '3',
      });
      yield put({
        type: meActions.getMySkillupPayHistorySuccess.type,
        payload: data,
      });
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: meActions.getMySkillupPayHistoryFailure.type,
      });
    }
  });
}

function* watchGetMyEvents() {
  yield takeLatest(meActions.getMyEvents.type, function* () {
    try {
      const { data } = yield call(meAPI.getAppliedEventsAPI);
      yield put({
        type: meActions.getMyEventsSuccess.type,
        payload: data,
      });
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: meActions.getMyEventsFailure.type,
      });
    }
  });
}

function* watchSetWorkingCondition() {
  yield takeLatest(
    meActions.setWorkingCondition.type,
    function* (action: PayloadAction<setWorkingConditioPayload>) {
      try {
        const { mode, workingCondition } = action.payload;
        const { data } = yield call(meAPI.editWorkingCondition, workingCondition);
        yield put({
          type: meActions.setWorkingConditionSuccess.type,
          payload: data,
        });
        if (mode === 'EDIT') {
          yield put({
            type: coreActions.setToaster.type,
            payload: {
              visible: true,
              message: '헤드헌팅 설정 정보가 임시 저장되었습니다.',
              type: 'success',
            },
          });
        }
      } catch (err) {
        const { data, statusText } = err;
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: data || statusText,
            type: 'error',
          },
        });
      }
    },
  );
}

function* watchSetMatchingJob() {
  yield takeLatest(meActions.setMatchingJob.type, function* (action: PayloadAction<boolean>) {
    const subscribeMatchingJob = action.payload;
    try {
      yield call(meAPI.editWorkingCondition, {
        subscribeMatchingJob,
      });
      yield put({
        type: meActions.setMatchingJobSuccess.type,
        payload: subscribeMatchingJob,
      });
      window.scrollTo(0, 0);
      yield put({
        type: coreActions.setToaster.type,
        payload: {
          visible: true,
          message: subscribeMatchingJob
            ? '헤드헌팅이 신청되었습니다.'
            : '헤드헌팅이 해제되었습니다.',
          type: 'success',
        },
      });
    } catch (err) {
      const { data, statusText } = err;
      sendErrorToSentry(err);
      yield put({
        type: coreActions.setToaster.type,
        payload: {
          visible: true,
          message: data || statusText,
          type: 'error',
        },
      });
    }
  });
}

function* watchSetApplicantFiles() {
  yield takeLatest(meActions.setApplicantFiles.type, function* () {
    try {
      const {
        resumeRes: { data: resumeList },
        portfolioRes: { data: portfolioList },
        miniinternPortfolioRes: { data: miniinternPortfolioList },
      } = yield all({
        resumeRes: call(meAPI.getApplicantResumeList),
        portfolioRes: call(meAPI.getApplicantPortfolioList),
        miniinternPortfolioRes: call(meAPI.getApplicantMiniinternPortfolioList),
      });
      const payload = {
        resumeList,
        portfolioList,
        miniinternPortfolioList,
      };

      yield put({
        type: meActions.setApplicantFilesSuccess.type,
        payload,
      });
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: coreActions.setToaster.type,
        payload: {
          visible: true,
          message: 'SET_APPLICANT_FILES error',
          type: 'error',
        },
      });
    }
  });
}

function* watchGetResumeList() {
  yield takeLatest(meActions.getResumeList.type, function* () {
    try {
      const { data: resumeList } = yield call(meAPI.getApplicantResumeList);
      yield put({
        type: meActions.getResumeListSuccess.type,
        payload: resumeList,
      });
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: coreActions.setToaster.type,
        payload: {
          visible: true,
          message: '이력서를 불러오는데 실패하였습니다.',
          type: 'error',
        },
      });
    }
  });
}

function* watchDeleteApplicantPortfolio() {
  yield takeLatest(
    meActions.deleteApplicantPortfolio.type,
    function* (action: PayloadAction<number>) {
      try {
        yield call(meAPI.deleteApplicantPortfolio, action.payload);

        yield put({
          type: meActions.deleteApplicantPortfolioSuccess.type,
          payload: action.payload,
        });
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: '포트폴리오가 삭제되었습니다.',
            type: 'success',
          },
        });
      } catch (err) {
        const { data, statusText } = err;
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: data || statusText,
            type: 'error',
          },
        });
      }
    },
  );
}

function* watchSetApplicationsStatus() {
  yield takeLatest(
    meActions.setApplicationsStatus.type,
    function* (action: PayloadAction<ApplicationsStatusQueries>) {
      try {
        const { data } = yield call(meAPI.getApplicationsStatus, action.payload);

        yield put({
          type: meActions.setApplicationsStatusSuccess.type,
          payload: data,
        });
      } catch (err) {
        const { data, statusText } = err;
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: data || statusText,
            type: 'error',
          },
        });
      }
    },
  );
}

function* watchSetApplySettleAccountApplication() {
  yield takeLatest(
    meActions.setApplySettleAccountApplication.type,
    function* (action: PayloadAction<ApplySettleAccountApplicationQueries>) {
      try {
        const { data } = yield call(meAPI.getApplication, {
          noticeUrlSlug: action.payload.noticeUrlSlug,
        });
        yield put({
          type: meActions.setApplySettleAccountApplicationSuccess.type,
          payload: data,
        });
      } catch (err) {
        const { data, statusText } = err;
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: data || statusText,
            type: 'error',
          },
        });
      }
    },
  );
}

function* watchSetApplicationJoinedStatus() {
  yield takeLatest(
    meActions.setApplicationJoinedStatus.type,
    function* (action: PayloadAction<ApplicationJoinStatusQueries>) {
      try {
        yield call(meAPI.patchApplicationJoinStatus, action.payload);

        yield put({
          type: meActions.setApplicationJoinedStatusSuccess.type,
          payload: action.payload,
        });
      } catch (err) {
        const { data, statusText } = err;
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: data || statusText,
            type: 'error',
          },
        });
      }
    },
  );
}

export function* meSaga() {
  yield all([
    fork(watchGetMyEvents),
    fork(watchGetMyProjects),
    fork(watchGetMySkillupCourses),
    fork(watchGetMySkillupPayHistory),
    fork(watchSetWorkingCondition),
    fork(watchSetMatchingJob),
    fork(watchSetApplicantFiles),
    fork(watchGetResumeList),
    fork(watchDeleteApplicantPortfolio),
    fork(watchSetApplicationsStatus),
    fork(watchSetApplySettleAccountApplication),
    fork(watchSetApplicationJoinedStatus),
  ]);
}

export default me.reducer;
